<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          @click="
            $router.push({
              name: 'doghome'
            })
          "
          >DogStuff</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          @click="
            $router.push({
              name: 'doghome'
            })
          "
          >DogStuff</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          @click="
            $router.push({
              name: 'doghome'
            })
          "
          >DogStuff</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          @click="
            $router.push({
              name: 'doghome'
            })
          "
          >DogStuff</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          @click="
            $router.push({
              name: 'doghome'
            })
          "
          >DogStuff</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          @click="
            $router.push({
              name: 'doghome'
            })
          "
          >DogStuff</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
