var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
            name: 'doghome'
          })}}},[_vm._v("DogStuff")])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
            name: 'doghome'
          })}}},[_vm._v("DogStuff")])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
            name: 'doghome'
          })}}},[_vm._v("DogStuff")])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
            name: 'doghome'
          })}}},[_vm._v("DogStuff")])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
            name: 'doghome'
          })}}},[_vm._v("DogStuff")])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
            name: 'doghome'
          })}}},[_vm._v("DogStuff")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }